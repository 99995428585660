<template>
    <div>

        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc @ok="submit" size="md">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="group.id === null">Register Grade</h5>
                <h5 v-if="group.id !== null">Edit Grade</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">

                        <b-row>
                            <b-col cols="12">
                                <b-form-group labe-for="name" label="Name">
                                    <validation-provider #default="{ errors }" name="Name" rules="">
                                        <b-form-input id="name" name="name" size="md" v-model="group.name" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.name">{{
                                            serverErrors.name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-button size="sm" class="add-owner mb-1" style="float: right" variant="outline-primary"
                                    @click="addElement()">Add Grade</b-button>

                            </b-col>
                            <table class="table">
                                <thead>
                                    <tr class="text-center">
                                        <th>Grades</th>
                                        <th width="3%"> </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="element, index in  group.grades" :key="index">
                                        <td scope="row">
                                            <div>
                                                <b-form-group label-for="id" label="">
                                                    <validation-provider #default="{ errors }" name="id" rules="">
                                                        <b-form-select id="id" name="id" size="sm" v-model="element.id">
                                                            <b-form-select-option
                                                                :value="null">Select...</b-form-select-option>
                                                            <b-form-select-option v-for="grade in hgrades" :key="grade.id"
                                                                :value="grade.id">
                                                                {{ grade.code }}
                                                            </b-form-select-option>
                                                        </b-form-select>
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                        <small class="text-danger" v-if="serverErrors && serverErrors.id">{{
                                                            serverErrors.id[0]
                                                        }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </div>
                                        </td>
                                        <td>
                                            <b-button variant="outline-danger" size="sm" @click="removeRow(index)">
                                                <feather-icon icon="TrashIcon" size="11"
                                                    class="text-danger stroke-current" />
                                            </b-button>
                                        </td>
                                        <!-- {{ element }} -->
                                    </tr>
                                </tbody>
                            </table>
                        </b-row>

                        <!-- <pre>
                            {{ group }}
                        </pre> -->
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->

        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="sm" />
                            <b-button variant="primary" size="md" @click="invokeCreateForm()">
                                <span class="text-nowrap">Register Group</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table :busy="isBusy" striped hover small ref="records" class="position-relative" :items="fetch" responsive
                :fields="columns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc">

                <template #cell(grades)="data">
                    <table>
                        <tr>
                           
                            <td class="cell cyan text-center" v-for="grade in data.item.grades">
                                {{grade.code}}<br>
                                <sub> {{grade.name}}</sub>
                            </td>
                        </tr>
                    </table>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
    BSpinner, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import gradeGroupsStoreModule from '@/views/cromis/grades/grade_groups/gradeGroupsStoreModule'
import useGradeGroupsList from '@/views/cromis/grades/grade_groups/useGradeGroupsList'

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner, BBadge,
        ValidationObserver, ValidationProvider,
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const grades = ref([])
        const hgrades = ref([])

        const group = ref({
            id: null,
            name: null,
            grades: [
                {
                    id: null,
                }
            ]
        })

        const CROMIS_STORE_MODULE_NAME = 'cromis-group'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, gradeGroupsStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            await store.dispatch('cromis-group/grades')
                .then(response => {
                    grades.value = response.data.grades
                    console.log(grades.value)
                    grades.value.forEach(ele => {
                        if (ele.type === 2) {
                            hgrades.value.push(ele)
                        }
                    });
                    console.log(hgrades.value)


                })
                .catch(error => {
                    console.log('got error', error)
                })
        })

        const changeSeason = async () => {
            season_id.value = grade.value.season_id
        }

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            isBusy,

            // Filers
            season_id,

            // Resolvers
            resolveStatusVariant,
        } = useGradeGroupsList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null

            group.value = {
                id: item.id,
                name: item.name,
                grades: item.grades
            }

            myModal.value.show()
        }

        const invokeCreateForm = () => {
            serverErrors.value = null

            group.value = {
                id: null,
                name: null,
                grades: [
                    {
                        id: null,
                    }
                ]
            }

            myModal.value.show()
        }

        const addElement = () => {
            group.value.grades.push({ id: '' })

            if (group.value.grades.length > 0) {
                console.log(group.value.grades)
            }
        }

        const removeRow = (index) => {
            console.log(index)
            if (group.value.grades.length > 1) {
                group.value.grades.splice(index, 1)
                group.value.grades = group.value.grades
            }
        }


        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (group.value.id === null || group.value.id === 0)
                handleCreate()
            else
                handleUpdate(group.value)
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }


            await store.dispatch('cromis-group/create', group.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Grade ${response.data.name} registered successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-group/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to group # ${response.data.name} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const remove = async (id) => {
            await store.dispatch('cromis-group/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            grades,
            hgrades,
            group,

            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            isBusy,

            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            changeSeason,
            invokeCreateForm,
            invokeUpdateForm,
            submit,
            isFormValid,
            handleCreate,
            handleUpdate,
            remove,
            resolveStatusVariant,
            removeRow,
            addElement
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>